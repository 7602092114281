import { withErrorBoundary, Spinner, NoResults } from 'shared'
import { MissingImage } from 'customizations'
import classNames from 'classnames'
import { absolutizePath } from 'shared/util'
import ParkCardBadge from './ParkCardBadge'
import ParkReservationSlots from './ParkReservationSlots'
import { useEffect, useState } from 'react';
import { SmartButtonLink } from 'ui/smart-buttons'

function ParkCard({ park, base = park.baseCollectionName, selectedDate }) {
  const [timeSlots, setTimeSlots] = useState([])

  let isCommunityPage = application.path.includes('/community-calendar') || application.path.includes('/places-calendar') || application.path.includes('/admin-places-calendar')
  let isAdminCalander = application.path.includes('/admin-places-calendar')
  let date = moment(selectedDate || application.todayDateString)

  const renderItem = (slot) => {
    let { time, reservation, available, militaryTime } = slot
    let options = { }
    let res = reservation
    if (isAdminCalander) {
      res = slot.reservation_admin_view
    }
    if (res) {
      if(slot.disabled) {
        options = {
          text: (
            <>
              Disabled: {slot.desc}
              {isAdminCalander && res.user_name && <br />}{isAdminCalander && res.user_name}
              {isAdminCalander && res.note && <br />}{isAdminCalander && res.note}
            </>
          ),
          className: classNames( 'btn-sm park-reservation-btn', { gray: true }),
          key: time,
        }
      }else {
        options = {
          text: (
            <>
              Cancel: {slot.desc}
              {isAdminCalander && res.user_name && <br />}{isAdminCalander && res.user_name}
              {isAdminCalander && res.note && <br />}{isAdminCalander && res.note}
            </>
          ),
          className: classNames( 'btn-sm park-reservation-btn', { gray: true }),
          key: time,
        }
      }
    } else {
      options = {
        text: (
          <>
            Open: {slot.desc}<br />
            {isAdminCalander && slot.res_user_admin_view && <br />}{isAdminCalander && slot.res_user_admin_view}
            {isAdminCalander && slot.res_note_admin_view && <br />}{isAdminCalander && slot.res_note_admin_view}
          </>
        ),
        className: 'btn-sm park-reservation-btn',
        key: time,
      }
    }

    if (User.current && User.current.guest) {
      options.click = () => {
        App.state.setObjectSilent({ afterSignupPath: App.path })
        App.go(`${base}/guest-signup`)
      }
    }
      let url = `${base}/${park.uid}`
      return <SmartButtonLink to={url} {...options} click={null} />
  }

  if (isCommunityPage) 
    useEffect(() => {
      async function fetchData() {
        ParkApi.loadTimeSlotsForDate(park, date, null).then((data) => setTimeSlots(data))
      }
      fetchData();
    }, [date, park]);

  return (
    <custom-card class={classNames('ParkCard', park.has_events && 'with-events')}>
      <Link to={`${absolutizePath(base)}/${park.uid}`} className='plain-link'>
        <div className="card">
          <div className="card-img-container">
            <img className="card-img-top"
              src={park.image_preview_url || MissingImage}
              alt={park.name}
            />
            <ParkCardBadge park={park} />
          </div>
          <div className="card-meta">
            { park.isFeatureEnabled('opening') && <div className="card-note float-right">{park.opening_status_str}</div> }
            <h5 className="card-heading">{park.name}</h5>
          </div>
        </div>
      </Link>
      {

        isCommunityPage &&
        <div className="park-reservation-time-slots">
          {
            timeSlots.length ?
              timeSlots.map(ts => renderItem(ts))
              :<NoResults className = 'compact' > No Availability Today < /NoResults>
          }
        </div>
      }
    </custom-card>
  )
}

export default withErrorBoundary(ParkCard)
